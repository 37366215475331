@charset "UTF-8";
/* CSS Document */
@import "../common/extend";

/*
  ------------------------------------
  #####################################
   #Common style
  ####################################
  ------------------------------------
*/
a{
    transition: 0.3s ease-in-out;
    opacity: 1;
    &:hover{
        opacity: 0.7;
    }
}

$dir-common: $dir + "common";
/* Reset */
@include reset;
.wrap {
  overflow: hidden;
  width: 100px;
}

.wrapper{
    width: 98%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 1%;
    background-color: #fef3dc;
    @include tublet{
        width: 94%;
        padding:5% 3%;
    }
	.main{
	  width: 100%;
	  margin: 0 auto;
	  @include tublet{
		width: 100%;
	  }
	}
}

html{
  background: #fff;
}
body {
  width: 100%;
  font-feature-settings: "palt";
  @include fo-go;
  line-height: $line-height;
  background: #fef3dc;
  font-size: $basefont;
  color: $black;
}

a {
  text-decoration: none;
  color: $black;
}

.inner {
  max-width: $sitesize;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  @include sitesize {
    padding: 0 3%;
  }
}

.pc {
  display: block;
}

.pc-flex {
  display: flex;
}

.pc-ib {
  display: inline-block;
}

.sp,
.sp-flex,
.sp-ib {
  display: none;
}


@include tublet {
  .sp {
    display: block;
  }
  .sp-flex {
    display: flex;
  }

  .sp-ib {
    display: inline-block;
  }

  .pc,
  .pc-flex,
  .pc-ib {
    display: none;
  }

}
@include sphone{
  .sp {
    display: block;
  }
}

body.lock{
  height: 100vh;
  overflow: hidden;
}
.inner_box{
  width: 96%;
  max-width: 1200px;
  margin: 0 auto;
  @include tublet{
	padding: 2%;
	box-sizing: border-box;
  }
}
header{
  padding: 20px 0;
  background-color:#fef3dc;
  .inner_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	align-items: center;
  }
  #logo{
    width: 18%;
    img{
      max-width: 100%;
	  vertical-align: top;
    }
	@include tublet{
	  width: 85px;
	}
  }
  .search.sp{
	  background-color: #e9dbc7;
	  box-sizing: border-box;
	  padding: 2.5% 4%;
	dl{
	  position: relative;
	  input{
		width: 100%;
		padding: 0.5em 1em;
		box-sizing: border-box;
		border:1px solid #e00010;
		border-radius: 20px;
		font-size: 120%;
		outline: none !important;
	  }
	  dd{
		position:absolute;
		top:50%;
		right:10px;
		transform: translateY(-50%);
		button{
		  display:block;
		  padding:6px;
		  background:none;
		  border:none;
		  span{
			display:block;
			width:16px;
			height:17px;
			background:url('../../img/common/search.png') no-repeat scroll 0 0;
			background-size: contain;
			@include tublet {
			  width:25px;
			  height:25px;
			}
		  }
		}
	  }
	}
  }
  #pc_header_inner{
    width: 80%;
    .subnav{
      display: flex;
	  justify-content: flex-end;
      .search{
		dl{
		  position: relative;
		  input{
			width: 100%;
			box-sizing: border-box;
			padding: 0.7em 1em;
			border:1px solid #e00010;
			border-radius: 2px;
			font-size: 100%;
			outline: none !important;
		  }
		  dd{
			position:absolute;
			background-color: #e00010;
			top:0;
			right:0;
			width:20%;
			height: 100%;
			a{
			  display: block;
			  width: 100%;
			  height: 100%;
			}
			button{
			  display:block;
			  padding:6px;
			  background:none;
			  border:none;
			  span{
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				display:block;
				width:50%;
				height:50%;
				background:url('../../img/common/search_w.png') no-repeat center center;
				background-size: contain;
			  }
			}
		  }
		}
	  }
      .user{
		width: 100%;
		text-align: right;
        li{
		  width: 25%;
		  text-align: center;
          display: inline-block;
		  font-size: 130%;
		  font-weight: bold;
		  box-sizing: border-box;
		  padding:0 3px;
		  vertical-align: top;
		  >a{
			display: block;
			background-color: #e00010;
			border-radius: 2px;
			color:#fff;
			padding: 0.6em 1em ;
			&:before{
			  content:"";
			  display: inline-block;
			  vertical-align:middle;
			  margin-right: 0.6em;
			}
		  }
		  &.login a:before{
			width: 18px;
			height: 18px;
			background: url("../../img/common/login.png") no-repeat center center;
			background-size: contain;
			margin-top: -2px;
		  }
		  &.logout a:before{
			width: 18px;
			height: 18px;
			background: url("../../img/common/logout.png") no-repeat center center;
			background-size: contain;
			margin-top: -2px;
		  }
		  &.cart a:before{
			width: 20px;
			height: 20px;
			background: url("../../img/common/cart.png") no-repeat center center;
			background-size: contain;
			margin-top: -2px;
		  }
        }
	  }
    }
    nav{
	  margin-top: 3%;
	  ul{
        display: flex;
		flex-wrap: wrap;
        justify-content: space-between;
		li{
		  width: 25%;
		  font-size: 128.57%;
		  font-weight: bold;
		  text-align: center;
		  box-sizing: border-box;
		  border-right: 1px solid #e00010;
		  vertical-align: top;
		  position: relative;
		  margin-bottom: 1em;
		  @include sitesize{
			font-size: 1.5vw;
		  }
		  a,.navbar-list-main-link,span{
			padding: 1em 3%;
			display: block;
			color: #e00010;

			&:hover{
			  .navbar-sub{
			  }
			}
		  }
          .navbar-sub{
            display: none;
          }
		  &:nth-child(4n + 1){
			border-left: 1px solid #e00010;
		  }
		}
		.navbar-list-category{
		  img{
			max-width: 71%;
		  }
		  >a{
			padding: 0;
		  }
		  .fixed-menu{
			z-index: 3;
			position: absolute;
			top:100%;left:0;
			width: 100%;
			font-size: 12px;
			display: none;
			background-color: #e00010;
			padding:1em;
			box-sizing: border-box;
			li{
			  width: 100%;
			  text-align: left;
			  margin-bottom: 10px;
			  &:last-child{
				margin-bottom: 0;
			  }
			  &:before{
				content:"";
				width: 0.5em;height: 0.5em;
				display: inline-block;
				vertical-align: middle;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: rotate(-45deg);
			  }
			  a{
				color:#fff;
				display: inline;
				&:hover{
				  text-decoration: underline;
				  opacity: 1;
				}

			  }
			}
		  }
		}
		.category-under{
		  &:hover{
			background-color:#e00010;
			&:before{
			  content:"";
			  display: inline-block;
			  width: 0;height: 0;
			  border: 6px solid transparent;
			  border-top-color: #fff;
			  position: absolute;
			  left:50%;bottom:0;
			  transform: translate3d(-50%,0,0);
			}
			.fixed-menu{
			  display: block;
			}
			span{
			  color:#fff;
			}
		  }
		}
	  }
    }
  }
  .toggle-menu{
	display: none;
  }
  #sp_header_inner{
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	transition: 1s;
	position: absolute;
	z-index: 9999;
	right:0;
	top:0;
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
	background-color:rgba(0,0,0,.7);
	nav{
	  transition: 1s;
	  transform: translateX(100%);
	  height: 100%;
	  overflow: scroll;
	}
	h2{
	  text-align: center;
	  color:#fff;
	  background-color: #E50211;
	  padding: 1em 0;
        
	}
	.toggle-menu {
	  .toggle-line {
		position: relative;
		width: 36px;
		left: 50%;
		transform: translateX(-50%);
		em {
		  height: 4px;
		  background: #fff;
		  left:50%;
		  &:nth-child(1) {
			top: 0;
			opacity: 1;
			transition: 0.4s ease-in;
			transform:translate(-50%,14px) rotate(45deg);
		  }
		  &:nth-child(2) {
			opacity: 1;
			transition: 0.4s 0.1s ease-in;
			@include trans-y;
			opacity: 0;
		  }
		  &:nth-child(3) {
			bottom: 0;
			opacity: 1;
			transition: 0.4s 0.2s ease-in;
			transform:translate(-50%,-14px) rotate(-45deg);
		  }
		}
	  }
	}
	&.open{
	  visibility: visible;
	  opacity: 1;
	  nav{
		transform: translateX(0);
	  }
	}
	.user{
	  background-color:#E50211;
	  height: 52px;
	  li{
		float: left;
		height: 100%;
		text-align: center;
		box-sizing: border-box;
		border-right: 1px solid #fff;
		&.cart{
		  width: 42.5%;
		  a:before{
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 16px;
			height: 16px;
			background: url("../../img/common/cart.png") no-repeat center center;
			background-size: contain;
			margin-right: 0.6em;
			margin-top: -2px;
		  }
		}
		&.login{
		  width: 42.5%;
		  a:before{
			content:"";
			display: inline-block;
			vertical-align: middle;
			width: 12px;
			height: 12px;
			background: url("../../img/common/login.png") no-repeat center center;
			background-size: contain;
			margin-right: 0.6em;
			margin-top: -2px;
		  }
		}
		&.toggle-menu{width: 15%;}
		a{
		  color:#fff;
		  display: block;
		  height: 100%;
		  line-height: 52px;
		}
	  }
	}
	.gnav{
	  background-color:#FEF3DC;
	  .logout{
		text-align: center;
		border-top: 1px solid #fff;
		background-color: #E50211;
		a{
		  color:#fff;
		}
	  }
	  li{
		text-align: center;
		border-bottom: 1px solid #E50211;
		a{
		  display: block;
		  padding: 1em 0;
		  color: #e10014;
		}
	  }
	}
	.category {
	  background-color: #FEF3DC;
	  >li {
		text-align: center;
		border-bottom: 1px solid #E50211;
		a {
		  display: block;
		  padding: 1em 0;
		  color: #E50211;
		}
		ul{
		  li{
			a{
			  &:before{
				content:"･";
				display: inline-block;
			  }
			}
		  }
		}
	  }
	}
	.pnav{
	  background-color: #f6e6c3;
	  padding: 1em 0;
	  li{
		padding:0.5em 1em;
		a{
		  color:#666;
		  &:before{
			content: "";
			display: inline-block;
			vertical-align: middle;
			background: url("../../img/common/pnav_arrow2.png") no-repeat center center;
			background-size: contain;
			width: 5px;
			height: 7px;
			margin-right: 1em;
			margin-top: -3px;
		  }
		}
	  }
	}
	.sns{
	  background-color:#FFF;
	  font-size: 110%;
	  li{
		border-bottom: 1px solid #e1d7d7;
		a{
		  color:#E50211;
		  padding: 1em ;
		  display: block;
		  &:before{
			content:"";
			display: inline-block;
			vertical-align: middle;
			margin-right: 0.5em;
		  }
		}
		&.facebook{
		  a:before{
			background: url("../../img/common/facebook.png") no-repeat center center;
			background-size: contain;
			width: 30px;
			height: 34px;
		  }
		}
		&.twitter{
		  a:before{
			background: url("../../img/common/twitter.png") no-repeat center center;
			background-size: contain;
			width: 30px;
			height: 27px;
		  }
		}
		&.instagram{
		  a:before{
			background: url("../../img/common/instagram.png") no-repeat center center;
			background-size: contain;
			width: 30px;
			height: 30px;
		  }
		}
	  }
	}
  }
  @include tublet{
	padding: 0 ;
	.toggle-menu {
	  @include flex;
	  align-items: center;
	  color: #fff;
	  font-size: 90%;
	  @include web-font01;
	  letter-spacing: 3px;
	  cursor: pointer;
	  span {
		margin-right: 20px;
	  }

	  .toggle-line {
		position: relative;
		width: 40px;
		height: 32px;

		em {
		  width: 100%;
		  height: 5px;
		  border-radius: 5px;
		  background: #e00010;
		  position: absolute;
		  display: block;
		  left: 0;
		  &:nth-child(1) {
			top: 0;
			opacity: 1;
			transition: 0.4s ease-in;
		  }

		  &:nth-child(2) {
			@include trans-y;
			left: 0px;
			opacity: 1;
			transition: 0.4s 0.1s ease-in;
		  }

		  &:nth-child(3) {
			bottom: 0;
			opacity: 1;
			transition: 0.4s 0.2s ease-in;
		  }
		}
	  }

	  &.active {
		.toggle-line {
		  em {
			&:nth-child(1) {
			  transform:translate(0,8px) rotate(45deg);
			}

			&:nth-child(2) {
			  @include trans-y;
			  left: 0px;
			  opacity: 0;
			}

			&:nth-child(3) {
			  transform:translate(0,-9px) rotate(-45deg);
			}
		  }
		}
	  }
	}
	.gnav{
	  li{
		&.active{
		}
		a{
		}
	  }
	}
  }
}
.foot{
  background-color: #e20014;
  .foot-main{
	display: flex;
	padding:20px 0px;
	justify-content: space-between;
	img{
	  width: 100%;
	}
	h2{
	  color:#fff;
	  font-size: 128.57%;
	  margin-bottom: 1em;
	}
	@include tublet {
	  flex-wrap: wrap;
	}
	.foot-info{
	  width:23%;
	  padding-right: 4%;
	  box-sizing: border-box;
	  font-size: 14px;
	  .foot-info-logo{
		margin-bottom: 10px;
	  }
	  li{
		margin-bottom: 10px;
		&:last-child{
		  margin-bottom: 0;
		}
	  }
	  @include tublet{
		order: 2;
		width: 100%;
		margin-right: 0px;
	  }
	}
	.foot-picup{
		width: 31%;
	  	padding-right: 7%;
		li{
		  margin-bottom: 10px;
		}
	}
	.foot-category{
	  	width:18%;
	  	padding-right: 6%;
		line-height: 1;
	  .base-category{
		box-sizing: border-box;
		margin-bottom: 1em;
		padding-left: 1em;
		position: relative;
		&:before{
		  position: absolute;
		  left:0;
		  top:3px;
		  content: "";
		  display: inline-block;
		  width:0.5em;
		  height:0.5em;
		  border-bottom: 1px solid #fff;
		  border-right: 1px solid #fff;
		  transform: rotate(-45deg);
		  vertical-align: middle;
		  margin-right: 0.5em;
		}
		a{
		  color:#fff;
		}
		ul{
		  padding-top: 1em;
		  li{
			margin-bottom: 1em;
			&:last-child{
			  margin-bottom: 0;
			}
			a:before{
			  content: "･";
			  display: inline-block;
			  vertical-align: middle;
			}
		  }
		}
	  }

	}
	.foot-island{
	  	width:26%;
	  	padding-right:7%;
	  line-height: 1;
	  .cat-island{
		  display: flex;
		  flex-wrap: wrap;
		  li{
			width: 50%;
			box-sizing: border-box;
			margin-bottom:1em;
			padding-left: 1em;
			position: relative;
			&:before{
			  position: absolute;
			  left:0;
			  top:3px;
			  content: "";
			  display: inline-block;
			  width:0.5em;
			  height:0.5em;
			  border-bottom: 1px solid #fff;
			  border-right: 1px solid #fff;
			  transform: rotate(-45deg);
			  vertical-align: middle;
			  margin-right: 0.5em;
			}
			a{
			  color:#fff;
			}
		  }
		}
	}
  }
  .foot-nav{
	@include tublet {
	  width: 100%;
	  order: 1;
	  margin-bottom: 60px;
	  .foot-nav-list {
		li{
		  width: 100%;
		  border-left: none;
		  border-bottom: 1px solid #9f6418;
		  margin-bottom: 0;
		  a{
			display: block;
			padding: 1.3em 0;
		  }
		}
	  }
	}
	  .foot-nav-list{
	  display: flex;
	  flex-wrap: wrap;
	  margin-top: 1em;
	  li{
		font-size: 14px;
		white-space: nowrap;
		padding: 0 1.3em;
		margin-bottom: 1em;
		border-left: 1px solid #9f6418;
		a{
		  color:#fff;
		}
	  }
	}
  }
  .foot-pnav{
	margin-bottom: 1em;
	font-size: 100%;
	text-align: center;
	li{
	  display: inline-block;
	  line-height: 1;
	  margin: 0 0.5em 1em;
	}
	a{
	  color:#fff;
	  &:before{
		content: "";
		display: inline-block;
		background:url('../../img/common/arrow_red.png') no-repeat center center ;
		background-size: contain;
		width: 1em;
		height: 1em;
		margin-right: 0.5em;
		vertical-align: text-bottom;
	  }
	}
  }
  .foot-copyright{
	text-align: center;
	padding: 16px;
	color: #FFF;
	font-size: 12px;
	  address{
	  font-style: normal;
	}
  }
  @include tublet{
	.inner_box,.foot-main{
	  padding: 0;
	  width: 100%;
	  text-align: center;
	}
	.foot-main{
	  display: none;
	}
	.foot-pnav{
	  display: block;
	  li{
		width: 100%;
		margin: 0 auto;
		border-bottom: 1px solid #fff;
		a{
		  display: block;
		  padding: 1em 0;
		  &:before{
			content: none;
		  }
		}
	  }
	}
	.foot-info-logo{
	  width: 40%;
	  margin: 0 auto 10px;
	  img{
		width: 100%;
	  }
	}
  }
}
.section-title{
  font-size: 214.29%;
  color: #e10010;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto 3%;
  position: relative;
  p{
	width: 48.24%;
	margin: 0 auto;
	font-family: 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
	@include tublet{
	  // width: 82.6666%;
	  width: 80%;
	}
  }
  //width: 48.24%;
  //font-size: 48px;
  @include tublet {
	font-size: 6.875vw;
	margin: 0 auto 3%;
  }
  span{
	position: relative;
	z-index: 2;
	background-color:#fef3dc;
	display: inline-block;
	padding:0 0.5em;
	vertical-align: top;
  }
  &:before{
	content: "";
	display:block;
	width: 100%;
	height: 6px;
	border:2px solid #E50211;
	box-sizing: border-box;
	position: absolute;
	top:50%;
	transform: translateY(-50%);
  }
}


.section-title2{
  font-family: a-otf-ud-shin-maru-go-pr6n, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  font-size: 200%;
  color:#e00010;
  border-top: 2px solid #e00010;
  border-bottom: 2px solid #e00010;
  padding: 0.8em 3%;
  box-sizing: border-box;
  margin-bottom: 3%;
}


.breadcrumb{
    display: block;
    text-align: right;
    font-size: 114.2857%;
    margin-bottom: 1.4%;
    @include tublet{
       font-size: 2.9875vw;
    }
    .breadcrumb-list{
        display: inline-block;
        color: #e00010;
        &:not(:last-child):after{
            content: ">";
            display: inline-block;
            width: 1.2em;
            text-align: center;
            color: #e00010;
        }
        a{
            color: #e00010;
            &:not(:last-child):after{
                content: "、";
                display: inline-block;
                width: 1em;
                text-align: center;
                color: #e00010;
            }
        }
    }
}