@charset "UTF-8";

%option_module_manual {
  /*
    ------------------------------------
    ####################################
     option_module

     ※ projectの基本設定
    ####################################
    ------------------------------------
  */
}

/*
  ------------------------------------
   MediaScreen指定
  ------------------------------------
*/

$sitesize: 1200px; // inner
$tublet: 768px; // Tublet
$sphone: 480px; // SP
/*
  ------------------------------------
   ベースプロパティ
  ------------------------------------
*/
$bg-color: #fff;
$color00: #e8a02b;
$white: #fff;
$black: #1b1b1b;
$red: #cb1b1d;
$gray: #eaeaea;
$gray2: #bababa;
$font-color: #333333;
$line-height: 1.5;
$a-color: #444;
$basefont: 14px;


/*
  ------------------------------------
   CSSからのIMGロード
  ------------------------------------
*/
$dir : "../../img/";

/*
  ------------------------------------
   FONT指定
  ------------------------------------
*/


// 丸ゴシック
@mixin fo-maru {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
}

// 明朝

@mixin fo-min {
  //ヒラギノ明朝
  //font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

  //遊明朝
  font-family: "Yu Mincho", "YuMincho",'Sawarabi Mincho',"ヒラギノ明朝 ProN W6", "HiraMinProN-W6", serif;
}

//ゴシック
@mixin fo-go {
  //ヒラギノ角ゴ
  font-family: 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

  //遊ゴシック
  //font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  //font-weight: 500;
}
//  webフォント
@mixin web-font01 {
  //font-family: 'Roboto', sans-serif;
}
@mixin web-font02 {
  //font-family: 'Roboto Slab', serif;
}
@mixin web-font03 {
  //font-family: 'Dancing Script', cursive;
}


/*
  ------------------------------------
   FORM設定
  ------------------------------------
*/

//RadioBox円形サイズ

$radioSize: 12px;
$radioSizeSmall: $radioSize - 2px;
$formItemsColor: #ff9344;
$inputBorder: #ccc;
$selectArrowColor: #333;


/*
  ------------------------------------
   Responsive指定
  ------------------------------------
*/
// Sitesize
@mixin sitesize {
  @media screen and (max-width: $sitesize) {
    @content;
  }
}

// Tublet
@mixin tublet {
  @media screen and (max-width: $tublet) {
    @content;
  }
}

// Sphone
@mixin sphone {
  @media screen and (max-width: $sphone) {
    @content;
  }
}

// 任意のサイズ
@mixin queryDown($query) {
  @media screen and (max-width: $query) {
    @content;
  }
}

@mixin queryUp($query) {
  @media screen and (min-width: $query) {
    @content;
  }
}
